import React, { useRef, useState, useEffect } from 'react';
import SearchCompanyEventType from './searchCompEventType';
import { companyEventTypeService } from '../../services/companyEventType.service';
import { useSelector } from 'react-redux';
import '../admin/admin.scss';
import {FaSave, FaTimesCircle} from "react-icons/fa";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {Checkbox} from "../components/checkbox/checkbox";
import {helperService} from "../../services/helper.service";
import {InputAdd} from "../components/inputAdd/inputAdd";
import MySwal from "sweetalert2";

export const AdminCompanyEventType = () => {
    const companyId = useSelector(state => state.userSettings.companyId)
    const gridRef = useRef();
    const [showEdit, setShowEdit] = useState(false);
    const [allowedToAddressCompanyEventTypes, setAllowedToAddressCompanyEventTypes] = useState([]);
    const [companyEventType, setCompanyEventType] = useState({
        CompanyEventTypeId: 0,
        EventTypeId: 0,
        CompanyEventTypeNotificationId: 0,
        IsRewarded: false,
        Reward: 0,
        IsNotification: false,
        NotificationMessage: 0,
        CompanyId: 0,
        IsAudienceAddressed: false,
        OnNewUserRegistry: false
    });
    const [catalogsFilters, setCatalogsFilters] = useState([]);
    const [eventTypeEdit, setEventTypeEdit] = useState('');

    const onSave = () => {
        setCompanyEventType({...companyEventType,
            CompanyId: companyId
        })

        if (companyEventType.CompanyEventTypeId === 0)
            companyEventTypeService.createCompanyActionType(companyEventType).then((result) => {
                gridRef.current.refreshDataGrid();
                MySwal.fire({
                    title: "¡Se creó correctamente!",
                    icon: "success",
                    confirmButtonText: "Confirmar"
                });
            });
        else
            companyEventTypeService.updateCompanyActionType(companyEventType).then((result) => gridRef.current.refreshDataGrid());

        setCompanyEventType({
            CompanyEventTypeId: 0,
            EventTypeId: 0,
            CompanyEventTypeNotificationId: 0,
            IsRewarded: false,
            Reward: 0,
            IsNotification: false,
            NotificationMessage: 0,
            CompanyId: 0,
            IsAudienceAddressed: false,
            OnNewUserRegistry: false
        });
        setShowEdit(false);
    };

    const handleOnNewCompanyEvent = () => {
        getCatalogFilters()
        setShowEdit(true);
    }

    const handleOnCancel = () => {
        setCompanyEventType({
            CompanyEventTypeId: 0,
            EventTypeId: 0,
            CompanyEventTypeNotificationId: 0,
            IsRewarded: false,
            Reward: 0,
            IsNotification: false,
            NotificationMessage: 0,
            CompanyId: 0,
            IsAudienceAddressed: false,
            OnNewUserRegistry: false
        });
        setShowEdit(false);
        setEventTypeEdit('')
    }

    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setCompanyEventType({
            CompanyEventTypeId: gridItem.companyEventTypeId,
            EventTypeId: gridItem.eventTypeId,
            CompanyEventTypeNotificationId: gridItem.companyEventTypeNotificationId,
            IsRewarded: gridItem.isRewarded,
            Reward: gridItem.reward,
            IsNotification: gridItem.isNotification,
            NotificationMessage: gridItem.notificationMessage,
            CompanyId: gridItem.companyId,
            Active: gridItem.active,
            IsAudienceAddressed: gridItem.isAudienceAddressed,
            OnNewUserRegistry: gridItem.onNewUserRegistry
        });
    }

    const getAllowedToAddressCompanyEventTypes = () => {
        companyEventTypeService.getAllowedToAddressCompanyEventTypes(process.env.REACT_APP_COMPANY)
            .then(res => setAllowedToAddressCompanyEventTypes(res));
    }

    const handleInputValue = (e) => setCompanyEventType({...companyEventType, [e.target.name]: e.target.value})

    const handleCheck = (value) => setCompanyEventType({...companyEventType, IsRewarded: value})

    const handleCheckUserRegistry = (value) => setCompanyEventType({...companyEventType, OnNewUserRegistry: value})

    const handleCheckNotification = (value) => setCompanyEventType({...companyEventType, IsNotification: value})

    const handleCheckAudience = (value) => setCompanyEventType({...companyEventType, IsAudienceAddressed: value})

    const handleChangeSelect = (value) => setCompanyEventType({...companyEventType, eventTypeId: value})

    async function getCatalogFilters() {
        helperService.getGenericCatalog('/backoffice/EventType/Filter')
            .then((result) => setCatalogsFilters(result.map(item => {
                return {
                    label: item.eventName,
                    value: item.eventTypeId,
                }
            })))
    }

    const handleCurrentPoints = (value) => setCompanyEventType({...companyEventType, Reward: parseInt(value)});

    useEffect(() => {
        getAllowedToAddressCompanyEventTypes();
    }, [])

    useEffect(() => {
        companyEventTypeService.getCompanyEventType()
            .then((result) => {
                let filter;
                result.map(item => {
                    if(item.companyEventTypeId === companyEventType.CompanyEventTypeId) setEventTypeEdit(item.eventTypeDesc)
                    return filter;
                })
            });
    }, [companyEventType])

    return (
        <>
            <TitleAdmin
                title={'Lista de tipos de evento'}
                subtitle={'Listado y alta de eventos'}
                btnBack={false}
            />
            <HeadingAdmin
                text={!(showEdit) ? 'Agregar tipo de evento' : 'Cancelar'}
                icon={(!showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
                event={() => !(showEdit) ? handleOnNewCompanyEvent() : handleOnCancel()}
            />
            {(showEdit) ?
                    <Form
                        layout={'vertical'}
                        className={'form-backoffice'}
                        onFinish={onSave}
                        fields={
                            [
                                {
                                    "name": ["IsRewarded"],
                                    "value": companyEventType.IsRewarded
                                },
                                {
                                    "name": ["eventTypeId"],
                                    "value": eventTypeEdit
                                },
                                {
                                    "name": ["Reward"],
                                    "value":companyEventType.Reward
                                },
                                {
                                    "name": ["IsNotification"],
                                    "value": companyEventType.IsNotification
                                },
                                {
                                    "name": ["NotificationMessage"],
                                    "value": companyEventType.NotificationMessage
                                },
                                {
                                    "name": ["IsAudienceAddressed"],
                                    "value": companyEventType.IsAudienceAddressed
                                },
                                {
                                    "name": ["OnNewUserRegistry"],
                                    "value": companyEventType.OnNewUserRegistry
                                },
                            ]
                        }
                    >
                        <Row gutter={16}>
                            <Col xs={24}>
                                <h4>{
                                    parseInt(companyEventType.CompanyEventTypeId) === 0 ?
                                        "Nuevo tipo de evento" : "Editar tipo de evento"}
                                </h4>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'¿Tiene recompensa?'}
                                    htmlFor={'IsRewarded'}
                                >
                                    <Checkbox
                                        text={'Sí, tiene recompensa'}
                                        label={'IsRewarded'}
                                        name={companyEventType.IsRewarded}
                                        handleValueCheck={handleCheck}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Tipo de evento'}
                                    htmlFor={'eventTypeId'}
                                    name={'eventTypeId'}
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo Requerido'
                                        }
                                    ]}
                                >
                                    {
                                        eventTypeEdit === '' ?
                                            <Select
                                                name={'eventTypeId'}
                                                onChange={handleChangeSelect}
                                                options={catalogsFilters}
                                                value={companyEventType.EventTypeId}
                                                disabled={(companyEventType.EventTypeId || catalogsFilters.length === 0)}
                                            />
                                            :
                                            <Input
                                                type={'text'}
                                                name={'eventTypeId'}
                                                disabled={(companyEventType.EventTypeId || catalogsFilters.length === 0)}
                                            />
                                    }
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Puntos'}
                                    htmlFor={'Reward'}
                                    name="Reward"
                                >
                                    <InputAdd
                                        maxLength={6}
                                        maxQuantity={99999}
                                        minQuantity={0}
                                        name={'Reward'}
                                        valueInt={companyEventType.Reward}
                                        handleCurrentNumber={handleCurrentPoints}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'¿Generar notificación?'}
                                    htmlFor={'IsNotification'}
                                >
                                    <Checkbox
                                        text={'Sí, generar notificación'}
                                        label={'IsNotification'}
                                        name={companyEventType.IsNotification}
                                        handleValueCheck={handleCheckNotification}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Texto de la notificación'}
                                    htmlFor={'NotificationMessage'}
                                    name="NotificationMessage"
                                    rules={[
                                        {
                                            required: true,
                                            message: '*Campo Requerido'
                                        }
                                    ]}
                                >
                                    <Input
                                        name="NotificationMessage"
                                        onChange={handleInputValue}
                                    />
                                </Form.Item>
                            </Col>
                            {allowedToAddressCompanyEventTypes.map(a => (a.eventTypeId)).includes(companyEventType.EventTypeId) &&
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={'¿Seleccionar la audiencia?'}
                                        htmlFor={'IsAudienceAddressed'}
                                    >
                                        <Checkbox
                                            text={'Sí, seleccionar audiencia'}
                                            label={'IsAudienceAddressed'}
                                            name={companyEventType.IsAudienceAddressed}
                                            handleValueCheck={handleCheckAudience}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {companyEventType.IsAudienceAddressed ?
                                allowedToAddressCompanyEventTypes.map(a => (a.eventTypeId)).includes(companyEventType.EventTypeId) &&
                                    <Col lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={'¿Se añadirán nuevos registros?'}
                                            htmlFor={'OnNewUserRegistry'}
                                        >
                                            <Checkbox
                                                text={'Sí, se añadirán nuevos registros'}
                                                label={'OnNewUserRegistry'}
                                                name={companyEventType.OnNewUserRegistry}
                                                handleValueCheck={handleCheckUserRegistry}
                                            />
                                        </Form.Item>
                                    </Col>
                                : null
                            }
                            <Col xs={24}>
                                <div className="form-backoffice-footer">
                                    <Button type="primary" htmlType={'submit'}>
                                        <p>Guardar</p>
                                        <FaSave />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                :
                <SearchCompanyEventType handleRowSelect={handleRowSelect} ref={gridRef} />
            }
        </>
    );
}
