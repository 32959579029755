import React, {forwardRef} from 'react';
import {FaEdit, FaTrash} from "react-icons/fa";
import {Button, Space, Table, Tag} from "antd";

const SearchAdminContactInfos = forwardRef((props, ref) => {

    const columns = [
        {
            title: 'Tipo de contacto',
            dataIndex: 'typeContact',
            key: 'typeContact',
            sorter: (a, b) => {
                if(a.typeContact.length < b.typeContact.length) {
                    return -1;
                }
                if(a.typeContact.length > b.typeContact.length) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend','ascend'],
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            width: 250,
        },
        {
            title: 'Es contacto principal',
            dataIndex: 'isContact',
            key: 'isContact',
        },
        {
            title: 'Mostrar sólo valor',
            dataIndex: 'isValue',
            key: 'isValue',
        },
        {
            title: 'Acciones',
            dataIndex: 'options',
            key: 'options',
        },
    ];

    const data = props.contactInfos.map(item => {
        return {
            typeContact: item.contactTypeName,
            url: (item.contactValue.length > 50) ? item.contactValue.substring(0,50) : item.contactValue,
            isContact: <Tag color={item.mainContact ? 'green' : 'red'}>{item.mainContact ? 'Sí' : 'No'}</Tag>,
            isValue: <Tag color={item.showValue ? 'green' : 'red'}>{item.showValue ? 'Sí' : 'No'}</Tag>,
            options: <Space>
                <Button
                    type="primary"
                    htmlType={'button'}
                    className="edit"
                    onClick={() => (item.genericContactInfoId > 0) ? props.prepareUpdateContactInfo(item) : ''}
                >
                    <FaEdit />
                </Button>
                <Button
                    type="primary"
                    htmlType={'button'}
                    className="delete"
                    onClick={() =>  props.handleContactInfoDelete(item)}
                >
                    <FaTrash />
                </Button>
            </Space>
        };
    })

    return (
        <Table
            className={'table-admin'}
            dataSource={data}
            columns={columns}
            locale={{
                triggerDesc: 'Ordenamiento descendente',
                triggerAsc: 'Ordenamiento ascedente',
                cancelSort: 'Cancelar ordenamiento',
                emptyText: 'Sin registros',
            }}
            scroll={{ x: '992px', y: 800 }}
            hideOnSinglePage={true}
            responsive={true}
        />
    );

});

export default SearchAdminContactInfos;
